<template>
  <div>
    <div style="position: relative" class="forum-list">
      <div style="position: sticky; top:0;background:white; z-index:2 !important;">
        <div class="loader" v-if="!entriesLoaded">
          <ion-spinner  name="dots"></ion-spinner>Loading comments
        </div>

        <div class="list" v-if="(user) && forumId && forumActive">
          <ion-item v-if="forumId && forumActive">
            <ion-textarea placeholder="Enter your comment" rows="2" v-model="forumEntry.text"></ion-textarea>
          </ion-item>

          <ion-button
              color="success" shape="round"
              size="small"
              expand="block"
              @click="sendForumEntry(forumEntry)"
              :class="{'button-disabled' : forumEntry.text.length == 0}"
          >Enter your comment
          </ion-button>
        </div>
        <div
            class="item item-text-wrap"
            v-if="!forumId || !forumActive"
        >There is no forum activated yet
        </div>
        <p
            class="item item-text-wrap item-icon-right"
            v-if="!user && forumId"
        >Please login to participate in the forum</p>
      </div>

      <div
          class="list forum-list"
          style="z-index:1 !important; margin:0 5px 20px 0px; display: flex; flex-direction: column-reverse;"
          v-if="forumActive"
      >

        <div v-for="(entry, key) in entries" :key="key" style="padding:3px 0">
          <div style="margin:2px 0px;" v-if="entry.userName">
            <ion-item-sliding>
              <ion-item style="margin:0px 0px; background-color: #d7efd7">
                <ion-label class="ion-text-wrap">
                  <ion-text>
                    <h2>“{{ entry.text }}“</h2>
                  </ion-text>

                  <ion-text color="medium">
                    <small v-if="entry.userName">
                      <small
                          style="color:#888888;font-size:12px"
                      >-{{ entry.userName }}, {{ moment(entry.time).format('dddd HH:mm') }}</small>
                    </small>
                  </ion-text>
                </ion-label>
                <ion-note
                    slot="end"
                    @click="openReply(entry)"
                    v-if="((!currentRepliedEntry || (currentRepliedEntry && currentRepliedEntry.id != entry.id)) && !entry.isReplyTo && (user)) && entry.userName"
                >
                  <ion-icon :name="'arrow-undo'" style="font-size:1.4rem"></ion-icon>
                </ion-note>
              </ion-item>

              <ion-item-options side="end">
                <ion-item-option color="danger" @click="deleteEntry(entry)">Delete</ion-item-option>
              </ion-item-options>
            </ion-item-sliding>

            <div class="list" style="margin:0 10px;" v-if="currentRepliedEntry && currentRepliedEntry.id == entry.id">
              <ion-item v-if="forumId && forumActive">
                <ion-textarea placeholder="Enter your comment" rows="2" v-model="replyEntry.text"></ion-textarea>
              </ion-item>
              <ion-row>
                <ion-col size="9">
                  <ion-button
                      size="small" shape="round"
                      color="success"
                      @click="sendForumEntry(replyEntry, entry.id)"
                      :class="{'button-disabled' : replyEntry.text.length == 0}"
                  >Send
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button color="danger" shape="round" size="small" @click="resetReply()">Cancel</ion-button>
                </ion-col>
              </ion-row>
            </div>
          </div>
          <div
              class
              v-for="reply in entry.replies"
              :key="reply"
              style="position: relative; padding:3px 0"
          >
            <ion-item-sliding v-if="entry.userName && reply.userName">
              <ion-item

              >
                <ion-label class="ion-text-wrap"
                           style="padding: 0 0 0  40px;margin:0px;border-style:none;border-bottom: 0px solid lightgrey">
                  <ion-text>
                    <h2>“{{ reply.text }}“</h2>
                  </ion-text>

                  <ion-text color="medium">
                    <small v-if="reply.userName">
                      <small>-{{ reply.userName }}, {{ moment(reply.time).format('dddd HH:mm') }}</small>
                    </small>
                  </ion-text>
                </ion-label>
              </ion-item>

              <ion-item-options side="end">
                <ion-item-option color="danger" @click="deleteEntry(reply)">Delete</ion-item-option>
              </ion-item-options>
            </ion-item-sliding>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import moment from "moment";
import {
  IonTextarea,
  IonButton,
  IonItem,
  IonLabel,
  IonText,
  IonRow,
  IonCol,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonIcon,
  IonNote,
    IonSpinner
} from "@ionic/vue";

import {addIcons} from "ionicons";
import {arrowUndo, trash} from "ionicons/icons";

addIcons({"arrow-undo": arrowUndo, trash});
const config = require('@/config');

export default defineComponent({
  data() {
    return {
      conf: config.conference,
      forumId: null,
      forumActive: false,
      forumEntry: {text: ""},
      replyEntry: {text: ""},
      allEntires: [],
      entries: [],
      currentRepliedEntry: null,
      user: {firstName: "Toni", lastName: "Tester"},
      entriesLoaded:false
    };
  },
  components: {
    IonTextarea,
    IonButton,
    IonItem,
    IonLabel,
    IonText,
    IonRow,
    IonCol,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonIcon,
    IonNote,
    IonSpinner
  },
  props: ["refId"],
  computed: {
    channel: function () {
      return this.conf + "_" + this.refId;
    }
  },
  methods: {
    joinChannel() {
      this.$socketInt.emit("join", this.channel);
    },
    getForum(refId) {
      this.$socketInt.emit("getForum", {
        congressId: this.conf + "",
        refId: refId
      });
    },

    async sendForumEntry(entry, replyTo) {
      let _entry = {
        congressId: this.conf + "",
        refId: this.refId,
        forumId: this.forumId,
        text: entry.text,
        userName: "Anonymous", // $rootScope.user ? $rootScope.user.firstName + ' ' + $rootScope.user.lastName : 'Anonymous',
        picture: null, //$rootScope.user ? $rootScope.user.picture : null,
        time: new Date().toISOString(),
        replyTo: replyTo
      };

      await this.$socketInt.emit("addForumEntry", _entry);
      this.forumEntry.text = "";
      this.replyEntry.text = "";
      this.currentRepliedEntry = null;
    },

    deleteEntry(entry) {
      //$scope.showConfirm = function () {
      /*var confirmPopup = $ionicPopup.confirm({
              title: '<span>Remove Comment</span>',
              template: '<span>Are you sure you want to remove this comment?</span>'
            });

            confirmPopup.then(async function (res) {
              if (res) {*/
      let _entry = {
        congressId: this.conf + "",
        refId: this.refId,
        entryId: entry.id,
        text: "This comment was removed",
        userName: "",
        picture: null,
        time: new Date().toISOString(),
        isReplyTo: entry.isReplyTo
      };
      console.log(_entry);
      this.$socketInt.emit("softDeleteForumEntry", _entry);
      /* } else {
                confirmPopup.close();
              }
            });*/
      //};
      //$scope.showConfirm();
    },

    sortEntries(entries) {
      return entries.sort(function (entry1, entry2) {
        if (entry1.id > entry2.id) return 1;
        if (entry1.id < entry2.id) return -1;

        if (entry1.time > entry2.time) return 1;
        if (entry1.time < entry2.time) return -1;
      });
    },
    openReply(entry) {
      this.currentRepliedEntry = entry;
    },
    resetReply() {
      this.currentRepliedEntry = null;
      this.replyEntry.text = "";
    }
  },

  created() {
    this.moment = moment;
    this.joinChannel();
    this.getForum(this.refId);

    this.$socketInt.on("getForum", res => {
      //console.log("getForum", res);
      if (res && res.refId === this.refId) {
        console.log("FORUM");
        this.forumId = res.id;
        this.forumActive = res.active;
        this.allEntries = this.sortEntries(res.ForumEntries);
        if (this.allEntries) {
          this.entries = {};
          for (let i = 0; i < this.allEntries.length; i++) {
            let entry = this.allEntries[i];
            if (entry.userName) {
              //let replyId = entry.isReplyTo ? parseInt(entry.isReplyTo) : -1;
              if (!entry.isReplyTo) {
                this.entries[entry.id] = entry;
                this.entries[entry.id]["replies"] = [];
              } else {
                if (this.entries[entry.isReplyTo]) {
                  this.entries[entry.isReplyTo]["replies"].push(entry);
                }
              }
            }
          }
        }
        this.entriesLoaded = true;
      } else {
        //console.log("NO FORUM");
        this.entriesLoaded = true;
      }
      //
    });

    //finished
    this.$socketInt.on("softDeleteForumEntry", res => {
      if (res) {
        console.log("on delete", res);
        if (!res.isReplyTo) {
          let oldReplies = this.entries[res.entryId]["replies"];
          this.entries[res.entryId] = res;
          this.entries[res.entryId]["replies"] = oldReplies;
        } else {
          if (this.entries[res.isReplyTo]) {
            let index = this.entries[res.isReplyTo]["replies"].findIndex(
                reply => reply.id == res.entryId
            );
            console.log("found reply at index ", index);
            this.entries[res.isReplyTo]["replies"][index] = res;
          }
        }
      }
    });

    this.$socketInt.on("addForumEntry", res => {
      if (res) {
        console.log("on add", res);
        if (!res.isReplyTo) {
          this.entries[res.id] = res;
          this.entries[res.id]["replies"] = [];
        } else {
          if (this.entries[res.isReplyTo]) {
            let replyExists = this.entries[res.isReplyTo]["replies"].find(
                reply => reply.id == res.id
            );
            if (!replyExists) {
              this.entries[res.isReplyTo]["replies"].push(res);
            }
          }
        }
      }
    });
  },
  unmounted() {
    //this.$socketInt.emit("leave", this.channel);
  }
});
</script>
<style lang="scss">
.loader{
  display:flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  ion-spinner{
    margin-right:10px;
  }
}
</style>